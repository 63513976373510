<template>
    <div class="underLogoPhrase">
        <h2 class="font-weight-bold mb-4 centers">Les Animaux S.O.S</h2>
        <p class="littlesize">
            Urgent !
        </p>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3" v-for="animau in animauxFiltres" :key="animau.id">
                <router-link :to="'/animaux/' + animau.id" class="card" style="height: auto; min-height: 350px;">
                    <div>
                        <img :src="'https://admin.asso-refuge.fr/images/' + animau.img1"
                            :alt="'photo du ' + animau.type + ' ' + animau.name" :class="'tailleimg'"
                            :title="animau.name + ' ' + animau.race" />
                    </div>
                    <div class="endessous" style="margin-top: 10px !important;margin-bottom: 30px;">
                        <h4 class="namedogtitleback">{{ animau.name }}</h4>
                        <h6>{{ animau.race }}</h6>
                        <h6><strong> Age: {{ calculateAge(animau.naissance) }}</strong></h6>
                        <i class="fas fa-plus-circle"></i>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

</template>


<script>
import convertAge from '../components/getAge.js';

export default {
    data() {
        return {
            animaux: [],
            animauxFiltres: []
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getSos/${idSite}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // Combine all animals into a single array
                this.animaux = data
                this.appliquerFiltre();
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        appliquerFiltre() {
            const type = this.$route.query.type || 'all';
            this.animauxFiltres = type === 'all'
                ? this.animaux
                : this.animaux.filter(animal => animal.type === type);
        },
        filtrerAnimaux(type) {
            this.$router.push({ path: this.$route.path, query: { type } });
        },
        calculateAge(dateOfBirth) {
            return convertAge(dateOfBirth);
        }
    },
    watch: {
        '$route.query.type': 'appliquerFiltre'
    }
};
</script>