<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Fiche de {{ animal.name }}
                <br>
            </h3>
            <div class="mid">
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4">


                    <span v-if="animal.huge_information"><b>Sexe :</b> {{ animal.huge_information }}</span>
                    <br>
                    <br>

                    <b>Date naissance :</b> {{ formaterDate(animal.naissance) }}

                    <br>
                    <br>
                    <b>Date d'arrivée :</b> {{ formaterDate(animal.arrive) }}
                    <br>
                    <br>
                    <span v-if="animal.sexe"><b>Sexe :</b> {{ animal.sexe }}</span>

                    <br>
                    <br>
                    <span v-if="animal.caractere"><b>Caractère :</b> {{ animal.caractere }}</span>

                    <br>
                    <br>


                    <span v-if="animal.ville"><b>Ville :</b> {{ animal.ville }}</span>

                    <br>
                    <br>


                    <span v-if="animal.race"><b>Race :</b> {{ animal.race }}</span>

                    <br>
                    <br>
                    <span v-if="animal.couleur"><b>Couleur :</b> {{ animal.couleur }}</span>

                    <br>
                    <br>
                    <span v-if="animal.entente_chien"><b>Entente avec chien :</b> {{ animal.entente_chien }}</span>

                    <br>
                    <br>
                    <span v-if="animal.entente_chat"><b>Entente avec chat :</b> {{ animal.entente_chat }}</span>

                    <br>
                    <br>
                    <span v-if="animal.entente_enfant"><b>Entente avec enfant :</b> {{ animal.entente_enfant }}</span>

                    <br>
                    <br>
                </div>
                <div class="col-lg-8 responsive">
                    <br>
                    <br>
                    <div v-html="animal.description"></div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6" v-if="animal.img1">
                            <br><br>

                            <img :src="'https://admin.asso-refuge.fr/images/' + animal.img1"
                                :alt="'photo du nacs ' + animal.name + ' de la '"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>
                        <div class="col-lg-6" v-if="animal.img2">
                            <br><br>
                            <img :src="'https://admin.asso-refuge.fr/images/' + animal.img2"
                                :alt="'photo du nacs ' + animal.name + ' de la '"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>


                        <div class="col-lg-6" v-if="animal.img3">
                            <br><br>
                            <img :src="'https://admin.asso-refuge.fr/images/' + animal.img3"
                                :alt="'photo du nacs ' + animal.name + ' de la '"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>

                        <div class="col-lg-6" v-if="animal.img4">
                            <br><br>
                            <img :src="'https://admin.asso-refuge.fr/images/' + animal.img4"
                                :alt="'photo du nacs ' + animal.name + ' de la '"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>

                        <div class="col-lg-6" v-if="animal.img5">
                            <br><br>
                            <img :src="'https://admin.asso-refuge.fr/images/' + animal.img5"
                                :alt="'photo du nacs ' + animal.name + ' de la '"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>
                    </div>
                    <br>
                    <br>


                </div>
            </div>
        </div>

        <br><br>
        <br><br>
        <br><br>
        <br><br>
    </div>

</template>

<style src="../assets/css/besoins.css"></style>
<script>
import convertAge from '../components/getAge.js';

export default {
    data() {
        return {
            animal: {},
            decode: null,
            formatdatearrive: null,
            formatdatenaissance: null
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getAnimalDetails/${idSite}/${this.$route.params.id}`)
            .then(response => response.json())
            .then(data => {
                this.animal = data[0];
                this.formatdatearrive = data.formatdatearrive;
                this.formatdatenaissance = data.formatdatenaissance;

                //name =    this.animal = data.animal.name;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        closeTab() {
            window.close();
        },
        calculateAge(dateOfBirth) {
            return convertAge(dateOfBirth);
        },
        formaterDate(dateString) {
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleDateString('fr-FR', options);
        }

    }
};

</script>