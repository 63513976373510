<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Les Informations </h3>
            <div class="mid">
            </div>
        </div>



        <div class="container">
            <div class="row">
                <div class="col-7">

                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>{{ informations.title
                                    }}:</b></h4>
                            <p v-html="informations.presentation" class=""></p>

                        </div>
                    </div>

                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <div class="main_slider" :style="{
                                backgroundImage: `url('https://admin.asso-refuge.fr/images/${informations.header_img}')`,
                                height: '450px', backgroundRepeat: 'no-repeat'
                            }"> </div>

                        </div>
                    </div>



                </div>
                <div class="col-5">
                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Email:</b></h4>
                            <p v-html="informations.email" class=""></p>
                        </div>
                    </div>
                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Adresse:</b></h4>
                            <p v-html="informations.address" class=""></p>
                        </div>
                    </div>
                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Telephone:</b></h4>
                            <p v-html="informations.phone" class=""></p>
                        </div>
                    </div>
                    <div class="card" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Horraire:</b></h4>
                            <p v-html="informations.horraire" class=""></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>

    </div>

</template>

<style src="../assets/css/besoins.css"></style>
<script>
export default {
    data() {
        return {
            informations: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getInformations/${idSite}`)
            .then(response => response.json())
            .then(data => {
                this.informations = data[0];
                console.log(data[0]);
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
