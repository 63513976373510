function convertAge(dateStr) {

    var birthDate = new Date(dateStr);
    var currentDate = new Date();
    var ageDelta = currentDate - birthDate;
    var ageInDays = ageDelta / (1000 * 60 * 60 * 24);
    var totalMonths = Math.floor(ageInDays / 30);
    var years = Math.floor(totalMonths / 12);
    var months = totalMonths % 12;


    if (years < 30) {
        return years + ' an(s) et ' + months + " mois"
    } else {
        return "Inconnu";
    }
}

export default convertAge;