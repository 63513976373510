<template>
  <homeDesktop />
  <footerDesktop />
  <footerMobile v-if="isMobile" />
</template>


<script setup>
import { computed } from 'vue'
import { useWindowSize } from '@vueuse/core'
import homeDesktop from './components/homeDesktop.vue'
import footerDesktop from './components/footerDesktop.vue'
import footerMobile from './components/footerMobile.vue'

const { width } = useWindowSize()

const isMobile = computed(() => width.value <= 800)

</script>