import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';


import App from './App.vue';

import Home from './view/homeVue.vue';
import Animaux from './view/animauxVue.vue';
import Sos from './view/sosVue.vue';
import Ferme from './view/FermeVue.vue';
import Service from './view/serviceVue.vue';
import Famille from './view/FamilleVue.vue';



import Adopter from './view/adopteVue.vue';
import Partenaire from './view/partenaireVue.vue';
import Shops from './view/shopVue.vue';
import Besoins from './view/besoinsVue.vue';
import Information from './view/informationVue.vue';
import AnimalPage from './view/animauxDetails.vue';
import NewsPage from './view/newsPage.vue';


const routes = [
    { path: '/redirect', redirect: '/' },
    { path: '/', component: Home },
    { path: '/partenaires', component: Partenaire },
    { path: '/shops', component: Shops },
    { path: '/besoins', component: Besoins },
    { path: '/sos', component: Sos },
    { path: '/animaux_ferme', component: Ferme },
    { path: '/services', component: Service },
    { path: '/adopte', component: Adopter },
    { path: '/recherche_famille', component: Famille },
    { path: '/info_refuge', component: Information },
    { path: '/animaux', component: Animaux },
    { path: '/publication/:id', component: NewsPage },
    { path: '/animaux/:id', component: AnimalPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else {
            return { top: 0 };
        }
    },
});

// Créer l'application Vue
const app = createApp(App);
const currentUrl = window.location.href;
if (currentUrl.includes("asso-refuge")) {
    app.config.globalProperties.$idSite = 15;
}
if (currentUrl.includes("as-os-atout")) {
    app.config.globalProperties.$idSite = 18;
}
if (currentUrl.includes("entraidechatsvillejuif")) {
    app.config.globalProperties.$idSite = 16;
}
if (currentUrl.includes("association-simba")) {
    app.config.globalProperties.$idSite = 19;
}

// Utiliser le routeur
app.use(router);

// Monter l'application
app.mount('#app');