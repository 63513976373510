<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Nos différents services</h3>
            <div class="mid">
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12" v-for="service in  services " v-bind:key="service.id">
                <div class="">
                    <div class="container " style="margin-top: 20px;">
                        <h4 class="titleblue titlecontainershop"><b>{{ service.titre }}</b></h4>
                        <br>
                        <p v-html="service.content" class="contentclassService"></p>
                        <div class="row">
                            <div class="col-lg-4">
                                <img :src="'https://admin.asso-refuge.fr/images/' + service.image"
                                    :alt="'illustatration d un service de l association'"
                                    style="display: block;margin-left: auto;margin-right: auto; width: 100%;" />

                            </div>
                            <div class="col-lg-4">
                                <img :src="'https://admin.asso-refuge.fr/images/' + service.image2"
                                    :alt="'illustatration d un service de l association'"
                                    style="display: block;margin-left: auto;margin-right: auto; width: 100%;" />

                            </div>
                            <div class="col-lg-4">
                                <img :src="'https://admin.asso-refuge.fr/images/' + service.image3"
                                    :alt="'illustatration d un service de l association'"
                                    style="display: block;margin-left: auto;margin-right: auto; width: 100%;" />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>

</template>

<style src="../assets/css/besoins.css"></style>
<script>
export default {
    data() {
        return {
            services: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getServices/${idSite}`)
            .then(response => response.json())
            .then(data => {
                const formattedServices = data.map(service => ({
                    ...service,
                    content: service.content.replace(/\r\n/g, '<br>'),
                }));
                this.services = formattedServices;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
