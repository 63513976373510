<template>
    <div class="mobilefooter">
        <div class="row">
            <div class="col-3 menubot">
                <router-link to="/">
                    <i class="fas fa-home jump"></i>
                    <span> Home</span>
                </router-link>
            </div>
            <div class="col-3 menubot">
                <router-link to="/Animaux?type=chien">
                    <i class="fas fa-paw jump"></i>
                    Chien
                </router-link>
            </div>
            <div class="col-3 menubot">
                <router-link to="/Animaux?type=chat">
                    <i class="fas fa-paw jump"></i>
                    Chat
                </router-link>
            </div>
            <div class="col-3 menubot">
                <router-link to="/animaux?type=nacs">
                    <i class="fas fa-paw jump"></i>
                    Nacs
                </router-link>
            </div>
        </div>
    </div>
</template>

<style src="../assets/css/template.css"></style>