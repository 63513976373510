<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">{{ news.nom }}</h3>
            <div class="mid">
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">

                    <div class="" style="margin-bottom: 0px;">
                        <div class="container " style="margin-top: 20px;">
                            <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>{{ news.nom
                                    }}:</b></h4>
                            <p v-html="formattedArticle" class=""></p>

                        </div>
                    </div>

                    <div class="col-lg-6" v-if="news.image">
                        <br><br>
                    </div>
                    <div class="" style="margin-bottom: 0px;background-color: transparent;">
                        <div class="container " style="margin-top: 20px;">
                            <img class="responsive_img" :src="'https://admin.asso-refuge.fr/images/' + news.image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>

    </div>

</template>

<script>
export default {
    data() {
        return {
            news: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getNewsDetails/${idSite}/${this.$route.params.id}`)
            .then(response => response.json())
            .then(data => {
                this.news = data[0];
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    computed: {
        formattedArticle() {
            if (this.news && this.news.article) {
                // Remplacer \r\n par <br>
                return this.news.article.replace(/\r\n/g, '<br>');
            }
            return '';
        },
    }

};

</script>
